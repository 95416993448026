function setEqualHeight() {
    $(".video-and-description .video-and-text").matchHeight();
    $(".grid-product .grid-element-title").matchHeight();
    $(".grid-product .product-options").matchHeight();
    $(".grid-product .productshortdesc").matchHeight();
    $(".grid-product .productshortattributes").matchHeight();
    $(".grid-product .prices-small").matchHeight();
    $(".grid-product .nakup").matchHeight();
    $(".news-image-wrapper,.news-content-wrapper").matchHeight();
}
$(window).on("load", function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$('#main-menu-state').on("change",function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $menu.css("max-height",maxHeight + "px");
                if ($menu.outerHeight() > maxHeight) {
                    $menu.css("height",maxHeight + "px");
                }
        	}
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $menu.css("max-height","");
            $menu.css("height","");
        });
    }
});


$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit = 190;
    switch (current_breakpoint) {
        case "xxxlarge":
            scroll_limit = 190;
            break;
        case "xxlarge":
            scroll_limit = 190;
            break;
        case "xlarge":
            scroll_limit = 190;
            break;
        case "large":
            scroll_limit = 160;
            break;
        case "medium":
            scroll_limit = 40;
            break;
        case "small":
            scroll_limit = 40;
            break;
        case "xsmall":
            scroll_limit = 40;
            break;
        default:
            scroll_limit = 190;
            break;
    }
    if ($(window).scrollTop() >= scroll_limit) {
        $("#header-wrapper").addClass("fixed");
        $("#header-wrapper").css("margin-top", -scroll_limit + "px");
    } else {
        $("#header-wrapper").removeClass("fixed");
        $("#header-wrapper").css("margin-top", -$(window).scrollTop() + "px");
    }
});
